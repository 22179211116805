import React from 'react';

import './connect.css';

// components
import ConnectForm from './ConnectForm/ConnectForm';
import Wrapper from '../Layout/Wrapper/Wrapper';
import ConnectHeader from './ConnectHeader/ConnectHeader';

const Connect = () => {
  return (
    <div id="connect-root">
      <Wrapper>
        <div id="connect-card">
          <ConnectHeader />
          <ConnectForm /> 
        </div>
      </Wrapper>
    </div>
  );
};

export default Connect