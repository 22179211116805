import React, { useState, useEffect } from 'react';
import { useMutation } from "react-query";
import './connectForm.css';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';
import Select from 'react-select';

// shared
import colors from '../../../shared/constants/colors';
import contactFormApi from '../../../shared/api/contactForm';
import { useAlert } from '../../../shared/context/alert';
import { isValidEmail } from '../../../shared/utils/utils';

const DEFAULT_FORM_STATE = {
  name: '',
  email: '',
  phone: '',
  referral: '',
  industry: '',
  interestedServices: '',
  budget: '',
  additionalInfo: '',
};

const Connectform = () => {
  const { alert, showAlert } = useAlert();

  const [erroredInput, setErroredInput] = useState({
    name: false,
    email: false,
    phone: false,
    referral: false,
    industry: false,
    interestedServices: false,
    budget: false,
  });

  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

  const onChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const sendForm = useMutation((formData) => contactFormApi.sendInquiryForm(formData), {
    onSuccess: () => {
      console.log('successfully sent form!');
      showAlert('Your message has been sent! A member from our team will be in contact with you shortly.', 'success');
      resetForm();
    },
    onError: (error) => {
      console.log(error);
      showAlert('An error has occurred while submitting your form. Please wait and try again later.', 'error');
    }
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const erroredInputCopy = { ...erroredInput };

    const containsValues = handleEmptyValidation();
    const isEmailValid = isValidEmail(formState.email);

    if (!containsValues) {
      showAlert('Please ensure all required fields are filled out.', 'error');
      return;
    }

    if (!isEmailValid) {
      showAlert('Please enter a valid email address.', 'error');
      erroredInputCopy.email = true;
      setErroredInput(erroredInputCopy);
      return;
    }
    sendForm.mutate(formState);
  };

  const handleEmptyValidation = () => {
    const { name, email, phone, referral, industry, interestedServices, budget } = formState;
    const erroredInputCopy = { ...erroredInput };

    erroredInputCopy.name = !name;
    erroredInputCopy.email = !email;
    erroredInputCopy.phone = !phone;
    erroredInputCopy.referral = !referral;
    erroredInputCopy.industry = !industry;
    erroredInputCopy.interestedServices = !interestedServices;
    erroredInputCopy.budget = !budget;

    setErroredInput(erroredInputCopy);

    return !!(name && email && phone && industry && interestedServices && budget);
  };

  const resetForm = () => {
    setFormState(DEFAULT_FORM_STATE);
  };

  const onSelectChange = (option) => {
    setFormState({ ...formState, referral: option?.value })
  };

  const selectDropdownStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: 'none',
      borderColor: erroredInput.referral ? `red` : 'white',
      fontSize: '1rem',
      margin: '.4rem 0 1rem 0',
      color: 'black',
      boxShadow: 'rgba(143, 143, 143, 0.15) 0px 2px 8px',
      '&:hover': {
        outline: 'none',
        borderColor: 'white',
      },
      '&:focus': {
        outline: 'none',
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? colors.green : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: colors.green,
        color: 'white',
      }
    }),
  };

  return (
    <form id="connect-form_form" onSubmit={onSubmit}>
      <div className="connect_form-input_block">
        <label htmlFor="name" className="connect_form-label">Name</label>
        <input
          className="connect_form-input"
          name="name"
          type="text"
          value={formState.name}
          onChange={onChange}
          style={{ borderColor: erroredInput.name ? `red` : `` }}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="email" className="connect_form-label">Email</label>
        <input
          className="connect_form-input"
          name="email"
          type="text"
          value={formState.email}
          onChange={onChange}
          style={{ borderColor: erroredInput.email ? `red` : `` }}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="phone" className="connect_form-label">Phone</label>
        <input
          className="connect_form-input"
          name="phone"
          type="tel"
          value={formState.phone}
          onChange={onChange}
          style={{ borderColor: erroredInput.phone ? `red` : `` }}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="referral" className="connect_form-label">How did you hear about Salt Media?</label>
        <Select
          name="referral"
          isClearable
          styles={selectDropdownStyles}
          onChange={onSelectChange}
          options={[
            { value: 'Social Media', label: 'Social Media' },
            { value: 'Referral', label: 'Referral' },
            { value: 'Google', label: 'Google' },
            { value: 'Other', label: 'Other', defaultValue: true },
          ]}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="industry" className="connect_form-label">What's your primary industry?</label>
        <input
          className="connect_form-input"
          name="industry"
          type="text"
          value={formState.industry}
          onChange={onChange}
          style={{ borderColor: erroredInput.industry ? `red` : `` }}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="interestedServices" className="connect_form-label">What services are you interested in?</label>
        <textarea
          name="interestedServices"
          className="connect_form-input"
          value={formState.interestedServices}
          onChange={onChange}
          rows={5}
          style={{ borderColor: erroredInput.interestedServices ? `red` : `` }}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="budget" className="connect_form-label">What's your budget?</label>
        <input
          className="connect_form-input"
          name="budget"
          type="number"
          value={formState.budget}
          onChange={onChange}
          style={{ borderColor: erroredInput.budget ? `red` : `` }}
        />
      </div>
      <div className="connect_form-input_block">
        <label htmlFor="additionalInfo" className="connect_form-label">Additional Info</label>
        <textarea
          name="additionalInfo"
          className="connect_form-input"
          value={formState.additionalInfo}
          onChange={onChange}
          rows={5}
        />
      </div>
      <Wrapper styles={{ justifyContent: "flex-end", margin: "1rem 0 0 0" }}>
        <Button type="submit" loading={sendForm.isLoading} style={{ backgroundColor: colors.green, color: "white" }}>Submit</Button>
      </Wrapper>
    </form>
  );
};

export default Connectform;