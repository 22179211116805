import React from 'react';

import './connectHeader.css';
import Portrait from '../../../assets/intro_img.jpg';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';

//shared 
import colors from '../../../shared/constants/colors';

const ConnectHeader = () => {
  return (
    <div id="connect_header-root">
      <Wrapper>
        <div id="connect_header-img_overlay">
          <img id="connect_header-img" src={Portrait} />
        </div>
      </Wrapper>
    </div>
  );
};

export default ConnectHeader;