import React, { useEffect, useState } from 'react';
import { useAlert } from '../../../shared/context/alert';

import './alert.css';

// components
import Wrapper from '../Wrapper/Wrapper';

// shared
import colors from '../../../shared/constants/colors';

const Alert = () => {
  const { alert, hideAlert } = useAlert();

  if (!alert.open) return null;

  const returnAlertTypeStyles = () => {
    switch (alert.type) {
      case 'success':
        return {
          backgroundColor: colors.alertSuccess,
          borderColor: colors.alertSuccessBorder,
          color: 'black'
        };
      case 'error':
        return {
          backgroundColor: colors.alertErr,
          borderColor: colors.alertErrBorder,
        };
      case 'warn':
        return {
          backgroundColor: colors.alertWarn,
          borderColor: colors.alertWarnBorder,
          color: 'black'
        };
      default:
        return {
          backgroundColor: '#2196F3',
          borderColor: '#2196F3',
          color: 'black',
        };
    }
  };

  return (
    <main id="alert" style={returnAlertTypeStyles()} className={`alert-${alert?.type}`}>
      <div id="alert-content_wrap">
        <p id="alert-text">{alert?.message}</p>
        <button id="alert-close_icon" onClick={hideAlert}>X</button>
      </div>
    </main>
  );
};

export default Alert;