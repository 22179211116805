import React, { useEffect, useState } from 'react';
import './scrollBar.css';

const ScrollBar = () => {
  const returnScrollLength = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if (height === 0) {
      return 0;
    }

    const totalScroll = (winScroll / height) * 100;

    // Ensure the totalScroll is a valid number and handle negative values
    if (isNaN(totalScroll) || totalScroll < 0) {
      return 0;
    }

    return (winScroll / height) * 100;
  };

  const [barWidth, setBarWidth] = useState(returnScrollLength());

  useEffect(() => {
    const updateScrollBar = () => {
      const length = returnScrollLength();
      setBarWidth(length + '%');
    };

    window.addEventListener('scroll', updateScrollBar);

    return () => window.removeEventListener('scroll', updateScrollBar);
  }, []);

  return (
    <div id="progressBarWrap">
      <div id="progressBar" style={{ width: barWidth }}></div>
    </div>
  );
};

export default ScrollBar;