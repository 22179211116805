import React from 'react';
import { PulseLoader } from 'react-spinners';
import './button.css';
import colors from '../../../shared/constants/colors';


const Button = ({ children, onClick, style, type, loading }) => {
  return <button onClick={onClick} style={{ ...style }} className="layoutButton" type={type}>
    {!loading ?
      children :
      <PulseLoader color={colors.babyBlue} speedMultiplier={0.8} size={15} className="layoutButtonLoader" />
    }
  </button>
};

export default Button;