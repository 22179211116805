import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";

// pages
import HomeContainer from './containers/HomeContainer';
import ConnectContainer from "./containers/ConnectContainer";
import NotFound from "./containers/NotFound/NotFound";

// components
import Nav from "./components/Layout/Nav/Nav";
import ScrollBar from "./components/Layout/ScrollBar/ScrollBar";
import Alert from "./components/Layout/Alert/Alert";
// context 
import { AlertProvider } from './shared/context/alert';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <ScrollBar />
        <Nav />
        <Alert />
        <Router>
          <Routes>
            {/* <Route path="/" element={<HomeContainer />} /> */}
            <Route path="/inquiry_form" element={<ConnectContainer />} />
            <Route path="*" element={<NotFound message="The page you're looking for does not exist!" />} />
          </Routes>
        </Router>
      </AlertProvider>
    </QueryClientProvider>
  );
}

export default App;