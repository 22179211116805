export default {
  cream: '#e4e2dd',
  dark: '#2b2b2b',
  red: '#d83d2e',
  babyBlue: '#78bde4',
  green: '#2f4230',
  purple: '#6469ab',
  alertSuccess: '#d5ffd9da',
  alertSuccessBorder: '#78e485ff',
  alertErr: '#fcd7d7da',
  alertErrBorder: '#ff2828ff',
  alertWarn: '#fe7e06da',
  alertWarnBorder: '#fe7e06ff',
}