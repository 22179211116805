import React from 'react';

// components
import Wrapper from '../components/Layout/Wrapper/Wrapper';
import Connect from '../components/Connect/Connect';

const ConnectContainer = () => {

  return (
    <Connect />
  );
};

export default ConnectContainer;