import { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    message: '',
    type: '',
    open: false,
  });
  
  const hideAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const showAlert = (message, type = 'info') => {
    setAlert({ message, type, open: true });
    setTimeout(() => {
      hideAlert();
    }, 5000);
  };


  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};