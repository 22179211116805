export const getMiddleOfScreen = () => {
  // const position = window.scrollY;
  // // scroll height of the entire document (not just the visible part)
  // const scrollableHeight = document.documentElement.scrollHeight;
  // // visible height of the browser window
  // const visibleHeight = window.innerHeight;
  // // middle of the visible height
  // const middleOfScrollY = (scrollableHeight - visibleHeight) / 2;
  // // image is rendered when the user scrolls to the middle of the page instead of the top.
  // // this will ensure that the render doesn't happen too late or too early.
  // const calculatedSumPosition = Math.floor(position + middleOfScrollY);
  // return calculatedSumPosition + 100;
  // Current scroll position from the top of the document
  const scrollPosition = window.scrollY;
  // Visible height of the browser window
  const visibleHeight = window.innerHeight;
  // Calculate the middle Y position of the visible part of the window
  const middleOfVisible = scrollPosition + (visibleHeight / 2);
  // console.log(middleOfVisible);
  return Math.floor(middleOfVisible + 100);
};

export const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

export const shouldRenderNavOnRoute = () => {
  const navRouteRender = {
    "/": false,
    "/connect": false,
  };

  return window.location.pathname in navRouteRender ? navRouteRender[window.location.pathname] : false;
};